/** @format */
/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { AllUserToAccount } from "../../services/UserToAccountService.js";

export default function ViewAllUserToAccountEmail({ userId }) {
  const [data, setData] = useState({});

  useEffect(() => {
    AllUserToAccount({ userId })
      .then((res) => {
        setData(res.data.userToAccountAccessGroupList);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Fragment>
      <DataTable value={data}>
        <Column field="userId" header={"userId"} />
        <Column field="Account.name" header={"accountId"} />
        <Column
          field="AccessGroup.name"
          header={"accessGroupId"}
          value={data.accessGroupId}
        />
        <Column field="User.email" header={"email"} />
      </DataTable>
    </Fragment>
  );
}
