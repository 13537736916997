/** @format */

import React, { Fragment, useRef, useState } from "react";
import { onChangeDataField } from "../Functions/OnChangeHandler.jsx";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { toastCreateSuccess } from "../Functions/Toast.jsx";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { CreateMessage } from "../../services/AccountService.js";

export default function AddMessage({
  accountId,
  refreshData,
  toggleCreateMessage,
}) {
  const toast = useRef(null);

  const [data, setData] = useState({
    data: {},
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await CreateMessage(data, accountId)
        .then((res) => {
          setData(res.data);
          refreshData();
          toastCreateSuccess(toast, "Message créé !");
          setTimeout(() => {
            toggleCreateMessage();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const optionsValue = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
    { label: "Push", value: "push" },
  ];

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>Création d&apos;un Message</h3>
          <InputText value={accountId} readOnly placeholder="accountId" />
          <div className="field">
            <InputText
              name="data.text"
              type="text"
              placeholder="Text"
              onChange={onChangeDataField({ data, setData })}
            />
          </div>
          <div className="field">
            <InputText
              name="recipient"
              type="text"
              placeholder="Client"
              onChange={onChangeDataField({ data, setData })}
            />
          </div>
          <div className="field">
            <Dropdown
              name="type"
              placeholder="Type"
              value={data.type}
              options={optionsValue}
              onChange={onChangeDataField({ data, setData })}
            />
          </div>

          <Button
            label="Ajouter"
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </Fragment>
  );
}
