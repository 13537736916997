/** @format */

import React, { Fragment, useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button, Dropdown, InputText } from "primereact";
import { toastUpdateSuccess } from "../Functions/Toast.jsx";
import { Toast } from "primereact/toast";
import { UpdateUserToAccount } from "../../services/AccountService";
import { SearchAccessGroup } from "../../services/AccessGroupService.js";

export default function UpdateUserToAccountAccessGroup({
  accountId,
  userId,
  refreshAllUserToAccount,
  toggleUpdateUserToAccount,
}) {
  const toast = useRef(null);
  const [state, setState] = useState({});

  const [accessGroupOptions, setAccessGroupOptions] = useState([]);

  const handleUpdateUserToAccount = (e) => {
    e.preventDefault();
    {
      UpdateUserToAccount(accountId, userId, state.accessGroupId).then(() => {
        toastUpdateSuccess(toast, "acces group modiife !");
        refreshAllUserToAccount();
        setTimeout(() => {
          toggleUpdateUserToAccount();
        }, 500);
      });
    }
  };

  const onChangeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    SearchAccessGroup().then((res) => {
      setAccessGroupOptions(res.data.accessGroupList);
    });
  }, []);

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={handleUpdateUserToAccount}>
          <h5>Update</h5>

          <div className="field">
            <InputText value={accountId} placeholder="accountId" />

            <InputText value={userId} placeholder="user" name="userId" />
            <Dropdown
              options={accessGroupOptions}
              name="accessGroupId"
              value={state.accessGroupId}
              onChange={onChangeHandler}
              optionLabel="name"
              optionValue="accessGroupId"
              placeholder="Select access group"
            />

            <Divider />
          </div>

          <Button
            label={"modifer"}
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </Fragment>
  );
}
