/** @format */

const onChange = (state) => {
  const onChangeHandler = (e) => {
    state.setData({
      ...state.data,
      [e.target.name]: e.target.value,
    });
  };
  return onChangeHandler;
};
const onChangeFilters = (filtersState) => {
  const onChangeFilters = (e) => {
    filtersState.setFilters({
      ...filtersState.filters,
      [e.target.name]: e.target.value,
    });
  };
  return onChangeFilters;
};

const onChangeDataField = (state) => {
  // Crée une fonction de gestionnaire d'événements qui prend en paramètre un événement
  const onChangeHandler = ({ target: { name, value } }) => {
    // Sépare le nom du champ en parties séparées par des points
    const fieldParts = name.split(".");
    // Si le nom du champ contient plus d'une partie (c'est-à-dire s'il s'agit d'un objet imbriqué),
    // met à jour l'état de manière récursive en parcourant les parties du champ de nom et en mettant à jour les données correspondantes
    if (fieldParts.length > 1) {
      let currentData = state.data[fieldParts[0]];
      for (let i = 1; i < fieldParts.length; i++) {
        const field = fieldParts[i];
        if (i < fieldParts.length - 1) {
          if (!currentData[field]) {
            currentData[field] = {};
          }
          currentData = currentData[field];
        } else {
          currentData[field] = value;
        }
      }

      state.setData({
        ...state.data,
        [fieldParts[0]]: state.data[fieldParts[0]],
      });
      // Si le nom du champ ne contient qu'une partie, met à jour l'état en utilisant la syntaxe de tableau d'objets
    } else {
      state.setData({
        ...state.data,
        [name]: value,
      });
    }
  };
  // Renvoie le gestionnaire d'événements créé
  return onChangeHandler;
};

const onChangeDataTextForMessage = (state) => {
  // Crée une fonction de gestionnaire d'événements qui prend en paramètre un événement
  const onChangeHandler = ({ target: { name, value } }) => {
    // Sépare le nom du champ en parties séparées par des points
    const fieldParts = name.split(".");
    // Si le nom du champ contient plus d'une partie (c'est-à-dire s'il s'agit d'un objet imbriqué),
    // crée une copie de l'objet de données initial en parcourant les parties du champ de nom et en mettant à jour les données correspondantes
    if (fieldParts.length > 1) {
      let currentData = { ...state };
      let currentPath = currentData;

      fieldParts.forEach((field) => {
        if (!currentPath[field]) {
          currentPath[field] = {};
        }
        currentPath = currentPath[field];
      });
      currentPath.text = value;

      return currentData;
      // Si le nom du champ ne contient qu'une partie, crée une copie de l'objet de données initial et met à jour la valeur correspondante
    } else {
      return {
        ...state,
        [name]: { text: value },
      };
    }
  };
  // Renvoie le gestionnaire d'événements créé
  return onChangeHandler;
};

export {
  onChange,
  onChangeFilters,
  onChangeDataField,
  onChangeDataTextForMessage,
};
