/** @format */

import React, { Fragment, useRef, useState } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { BreadCrumb, Button, SplitButton, Dropdown } from "primereact";
import { useNavigate } from "react-router";
import { Delete, Update } from "../../services/AccountService";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import AddUserToAccount from "../UserToAccount/AddUserToAccount.jsx";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import { Toast } from "primereact";
import { toastUpdateSuccess, toastCopyClipBoard } from "../Functions/Toast.jsx";
import SliceString from "../Functions/SliceString.jsx";
import AddMessage from "../Messages/AddMessage.jsx";

export default function DetailsAccount({
  accountId,
  refreshData,
  refreshAllUserToAccount,
  state,
}) {
  const toastCopy = useRef(null);
  const toastUpdate = useRef(null);
  const navigate = useNavigate();
  const [createDialogUserToAccount, setCreateDialogUserToAccount] =
    useState(false);
  const [createDialogAddMessage, setCreateDialogAddMessage] = useState(false);

  const handleDelete = () => {
    Delete(accountId).then((res) => {
      state.setData(res);
      navigate("/accounts");
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await Update(state.data, accountId).then((res) => {
        state.setData(res.data.account);
        toastUpdateSuccess(toastUpdate, "Données mise à jour");
        refreshData();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const items = [
    {
      label: "Comptes",
      url: "/accounts",
    },
    {
      label: <SliceString string={accountId} />,
      url: `/accounts/${accountId}`,
    },
  ];

  //Call to action
  const toggleCreateUserToAccount = () => {
    setCreateDialogUserToAccount(!createDialogUserToAccount);
  };
  //Call to action
  const toggleCreateMessage = () => {
    setCreateDialogAddMessage(!createDialogAddMessage);
  };

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: "Créer un message",
      icon: "pi pi-plus text-green-600",
      command: () => {
        toggleCreateMessage();
      },
    },
    {
      label: "Ajouter un utilsateur",
      icon: "pi pi-user-plus text-green-600",
      command: () => {
        toggleCreateUserToAccount();
      },
    },
    {
      label: "Supprimer le compte",
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopy = () => {
    navigator.clipboard.writeText(accountId);
    toastCopyClipBoard(toastCopy, "Contenu copié !");
  };

  const optionsValue = [
    { label: "NEW", value: "new" },
    { label: "OK", value: "ok" },
    { label: "KO", value: "ko" },
  ];
  
  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />;
      <Dialog
        visible={createDialogUserToAccount}
        style={{ width: "50vw", height: "100vh" }}
        modal
        onHide={toggleCreateUserToAccount}
      >
        <AddUserToAccount
          toggleCreateUserToAccount={toggleCreateUserToAccount}
          accountId={accountId}
          refreshAllUserToAccount={refreshAllUserToAccount}
        />
      </Dialog>
      <Dialog
        visible={createDialogAddMessage}
        style={{ width: "50vw", height: "100vh" }}
        modal
        onHide={toggleCreateMessage}
      >
        <AddMessage
          toggleCreateMessage={toggleCreateMessage}
          accountId={accountId}
          refreshData={refreshData}
          state={state}
        />
      </Dialog>
      <Card>
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>Compte</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label={"Action"}
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>
        <form
          className="card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{"Compte ID"}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.accountId}
                      readOnly
                      name="accountId"
                      type="text"
                      placeholder={"ID"}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopy}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Nom"}</h5>
                  <InputText
                    defaultValue={state.data?.name}
                    name="name"
                    type="text"
                    placeholder={"Nom"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Status"}</h5>
                  <Dropdown
                    name="status"
                    placeholder={state.data?.status}
                    defaultValue={state.data?.status}
                    options={optionsValue}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={"Mise à jour"}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastUpdate} />
          <Toast ref={toastCopy} />
        </form>
      </Card>
    </Fragment>
  );
}
