/** @format */
import http from "./axios.js";

const GetMessage = (messageId) => {
  return http.get(`/messages/${messageId}`, {
    withCredentials: true,
  });
};

const SearchMessage = (filters) => {
  return http.post(
    "/messages/search",
    {},
    {
      withCredentials: true,
      params: filters,
    }
  );
};

const Update = (data, messageId) => {
  return http.put(`/messages/${messageId}`, data, {
    withCredentials: true,
  });
};

const Delete = (messageId) => {
  return http.delete(`/messages/${messageId}`, {
    withCredentials: true,
  });
};
export { SearchMessage, GetMessage, Update, Delete };
