/** @format */

import React, { Fragment, useContext, useState } from "react";
import { Button } from "primereact/button";
import { LoginContext } from "../../context/context.js";

export default function LoginRegister() {
  const { isLogged, logout } = useContext(LoginContext);
  const [loginDialog, setLoginDialog] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);

  const onClickLogin = () => {
    window.location.href = "/login";
    setLoginDialog(!loginDialog);
  };
  const onClickRegister = () => {
    window.location.href = "/register";
    setSignUpDialog(!signUpDialog);
  };
  const onClickLogout = () => {
    logout();
    // logout().then(() => (window.location.href = "/login"));
  };

  return (
    <Fragment>
      {!isLogged ? (
        <Fragment>
          <Button
            label="Connexion"
            className="p-button-sm p-component p-button-raised p-button-success mr-2"
            onClick={onClickLogin}
          ></Button>

          <Button
            label="Inscription"
            className="p-button-sm p-component p-button-raised p-button-info"
            onClick={onClickRegister}
          ></Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            label="Deconnexion"
            className="p-button-sm p-component p-button-raised p-button-danger"
            onClick={onClickLogout}
          ></Button>
        </Fragment>
      )}
    </Fragment>
  );
}
