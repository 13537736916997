/** @format */

import http from "./axios.js";

const Register = (data) => {
  return http.post("/auth/register", data);
};

const Login = (data) => {
  return http.post("/auth/login", data, {
    withCredentials: true,
  });
};

const ForgetPasswordReq = (data) => {
  return http.post("/auth/forget-password", data);
};

const ResetPasswordReq = (data, token) => {
  return http.put(`/auth/reset-password/${token}`, data);
};

const Logout = async () => {
  await http.post("/auth/logout", {}, { withCredentials: true });
};
export { Register, Login, ForgetPasswordReq, ResetPasswordReq, Logout };
