/** @format */

import "./styles/App.scss";
import React, { Fragment, useContext, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Connexion from "./views/Auth/Login.jsx";
import Inscription from "./views/Auth/Register.jsx";
import ForgetPassword from "./views/Auth/ForgetPassword.jsx";
import ResetPassword from "./views/Auth/ResetPassword.jsx";
import { LoginContext, LoginProvider } from "../src/context/context.js";
import OneUser from "./views/User/OneUser.jsx";
import Users from "./views/User/Users.jsx";
import Header from "./components/Layout/Header.jsx";
import Accounts from "./views/Account/Accounts.jsx";
import OneAccount from "./views/Account/OneAccount.jsx";

import OneMessage from "./views/Message/OneMessage.jsx";
import Messages from "./views/Message/Messages.jsx";

export default function App() {
  const PrivateRoute = ({ children }) => {
    const { isLogged } = useContext(LoginContext);
    useEffect(() => {}, [isLogged]);
    return (
      <Fragment>
        {isLogged === false ? (
          <Navigate to="/login"></Navigate>
        ) : (
          <div>{children}</div>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <LoginProvider>
        <Router>
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/users/:userId"
              element={
                <PrivateRoute>
                  <OneUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <PrivateRoute>
                  <Accounts />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts/:accountId"
              element={
                <PrivateRoute>
                  <OneAccount />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages/:messageId"
              element={
                <PrivateRoute>
                  <OneMessage />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Connexion />} />
            <Route path="/register" element={<Inscription />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
          </Routes>
        </Router>
      </LoginProvider>
    </Fragment>
  );
}
