/** @format */
import React, { Fragment, useContext } from "react";
import { Menubar } from "primereact/menubar";
// import logo from "../../assets/img/logo_confortelect.svg";
import LoginRegister from "./LoginRegister.jsx";
import { LoginContext } from "../../context/context.js";
export default function Header() {
  const { isLogged } = useContext(LoginContext);
  const start = (
    <a href={"/"}>
      <div className="flex">
        <h1 style={{ fontSize: "1em" }}>ladd-notifications</h1>
      </div>
    </a>
  );
  const items = [
    // { label: "Fournisseurs", url: "/providers" },
    { label: "Utilisateurs", url: "/users" },
    { label: "Comptes", url: "/accounts" },
    { label: "Messages", url: "/messages" },
  ];
  const end = (
    <Fragment>
      <div className="flex align-items-center">
        <LoginRegister />
      </div>
    </Fragment>
  );
  return (
    <Fragment>
      {isLogged ? (
        <Fragment>
          <Menubar model={items} start={start} end={end}></Menubar>
        </Fragment>
      ) : (
        <Fragment>
          <Menubar model={[]} start={start} end={end}></Menubar>
        </Fragment>
      )}
    </Fragment>
  );
}
