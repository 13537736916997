/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";
import DetailsAccount from "../../components/Accounts/DetailsAccount.jsx";
import Title from "../../components/Functions/Title.jsx";
import ViewAllUserToAccount from "../../components/UserToAccount/AllUserToAccount.jsx";
import { GetAccount } from "../../services/AccountService.js";
import { AllUserToAccount } from "../../services/UserToAccountService";

export default function OneAccount() {
  const { accountId, userId } = useParams();
  const [data, setData] = useState({});
  const [allUserToAccount, setAllUserToAccount] = useState({});

  const refreshData = async () => {
    await GetAccount(accountId)
      .then((res) => {
        setData(res.data.account);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const refreshAllUserToAccount = async () => {
    await AllUserToAccount({ accountId, userId })
      .then((res) => {
        setAllUserToAccount(res.data.userToAccountAccessGroupList);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    refreshData();
    refreshAllUserToAccount();
  }, []);

  return (
    <Fragment>
      <Title title={data?.name} />
      <DetailsAccount
        accountId={accountId}
        state={{ data, setData }}
        refreshData={refreshData}
        refreshAllUserToAccount={refreshAllUserToAccount}
      />
      <ViewAllUserToAccount
        state={{ allUserToAccount, setAllUserToAccount }}
        accountId={accountId}
        refreshData={refreshData}
        refreshAllUserToAccount={refreshAllUserToAccount}
      />
    </Fragment>
  );
}
