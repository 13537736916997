/** @format */

import http from "./axios.js";

const CreateAccount = (data) => {
  return http.post("/accounts", data, { withCredentials: true });
};

const SearchAccount = (filters) => {
  return http.post(
    "/accounts/search",
    {},
    {
      withCredentials: true,
      params: filters,
    }
  );
};

const CreateMessage = (data, accountId) => {
  return http.post(`/accounts/${accountId}`, data, {
    withCredentials: true,
  });
};

const GetAccount = (accountId) => {
  return http.get(`/accounts/${accountId}`, { withCredentials: true });
};

const Update = (data, accountId) => {
  return http.put(`/accounts/${accountId}`, data, {
    withCredentials: true,
  });
};

const Delete = (accountId) => {
  return http.delete(`/accounts/${accountId}`, {
    withCredentials: true,
  });
};

const UpdateUserToAccount = (accountId, userId, accessGroupId) => {
  return http.put(
    `/accounts/${accountId}/users/${userId}`,
    { accessGroupId },
    {
      withCredentials: true,
    }
  );
};

const DeleteUserToAccount = (accountId, userId) => {
  return http.delete(`/accounts/${accountId}/users/${userId}`, {
    withCredentials: true,
  });
};

export {
  CreateAccount,
  CreateMessage,
  SearchAccount,
  GetAccount,
  Update,
  Delete,
  UpdateUserToAccount,
  DeleteUserToAccount,
};
