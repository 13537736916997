/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { Card } from "primereact/card";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";
import AllUsers from "./AllUsers.jsx";
import { Dialog } from "primereact/dialog";
import AddUser from "./AddUser.jsx";
import { SearchUser } from "../../services/UserService.js";

export default function Index() {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const [filters, setFilters] = useState({
    page: currentPage,
    size: itemsPerPage,
    userId: undefined,
    email: undefined,
    lastName: undefined,
    firstName: undefined,
  });

  const items = [{ label: "Liste des utilisateurs" }];

  const home = {
    icon: "pi pi-home",
    url: "/",
  };
  const [createDialogUser, setCreateDialogUser] = useState(false);

  //Call to action
  const toggleCreateUser = () => {
    setCreateDialogUser(!createDialogUser);
  };

  const refreshData = async () => {
    await SearchUser({ ...filters }).then((res) => {
      setData(res.data);
      setLoaded(true);
    });
  };

  useEffect(() => {
    refreshData();
  }, [currentPage, itemsPerPage, filters]);

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Dialog
        visible={createDialogUser}
        className="w-4 h-screen"
        modal
        onHide={toggleCreateUser}
      >
        <AddUser
          toggleCreateUser={toggleCreateUser}
          refreshData={refreshData}
          state={{ data, setData }}
        />
      </Dialog>
      <Card>
        {loaded ? (
          <AllUsers
            data={data}
            toggleCreateUser={toggleCreateUser}
            filtersState={{ filters, setFilters }}
            pageState={{
              currentPage,
              itemsPerPage,
              setCurrentPage,
              setItemsPerPage,
            }}
          />
        ) : (
          <Fragment>
            <div className="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </Fragment>
        )}
      </Card>
    </Fragment>
  );
}
