/** @format */

import React, { Fragment, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { CreateAccount } from "../../services/AccountService.js";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import { toastCreateSuccess } from "../Functions/Toast.jsx";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";

export default function AddAccount({
  refreshData,
  state,
  toggleCreateAccount,
}) {
  const toast = useRef(null);

  const optionsValue = [
    { label: "NEW", value: "new" },
    { label: "OK", value: "ok" },
    { label: "KO", value: "ko" },
  ];

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await CreateAccount(state.data)
      state.setData(res.data);
      refreshData();
      toastCreateSuccess(toast, "Compte créé !");
      setTimeout(() => {
        toggleCreateAccount();
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>Création d&apos;un compte</h3>
          <div className="field">
            <InputText
              name="name"
              type="text"
              placeholder="Nom"
              onChange={onChange(state)}
            />
          </div>
          <div className="field">
            <Dropdown
              name="status"
              placeholder="Status"
              value={state.data.status}
              options={optionsValue}
              onChange={onChange(state)}
            />
          </div>

          <Button
            label="Ajouter"
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </Fragment>
  );
}
