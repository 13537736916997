/** @format */

import http from "./axios.js";

const CreateAccessGroup = (data) => {
  return http.post("/access-groups", data, { withCredentials: true });
};

const SearchAccessGroup = (filters) => {
  return http.post(
    "/access-groups/search",
    {},
    {
      withCredentials: true,
      params: filters,
    }
  );
};

const DeleteAccessGroup = (accessGroupId) => {
  return http.delete(`/access-groups/${accessGroupId}`, {
    withCredentials: true,
  });
};

const CreateAccessGroupRule = (accessGroupId, data) => {
  return http.post(`/access-groups/${accessGroupId}/access-rules`, data, {
    withCredentials: true,
  });
};

const GetOneAccessGroup = (accessGroupId, accessGroupRuleId) => {
  return http.get(
    `/access-groups/${accessGroupId}/access-rules/${accessGroupRuleId}`,

    {
      withCredentials: true,
    }
  );
};

const SearchAccessGroupRule = (filters) => {
  return http.post(
    "/access-groups/access-rules/search",
    {},
    {
      withCredentials: true,
      params: filters,
    }
  );
};
const Update = (data, accessGroupId, accessGroupRuleId) => {
  return http.put(
    `/access-groups/${accessGroupId}/access-rules/${accessGroupRuleId}`,
    data,
    {
      withCredentials: true,
    }
  );
};

const DeleteAccessGroupRule = (accessGroupId, accessGroupRuleId) => {
  return http.delete(
    `/access-groups/${accessGroupId}/access-rules/${accessGroupRuleId}`,
    {
      withCredentials: true,
    }
  );
};

export {
  CreateAccessGroup,
  GetOneAccessGroup,
  DeleteAccessGroup,
  SearchAccessGroup,
  Update,
  CreateAccessGroupRule,
  DeleteAccessGroupRule,
  SearchAccessGroupRule,
};
