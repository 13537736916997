/** @format */

import React, { Fragment, useContext, useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { LoginContext } from "../../context/context";
import { Login } from "../../services/AuthService";

export default function Connexion() {
  const { login } = useContext(LoginContext);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    Login(data)
      .then((res) => {
        navigate("/");
        login(res.data);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "This account doesn't exist!",
          footer: "",
        });
      });
  };

  return (
    <Fragment>
      <div className="flex justify-content-center">
        <Card className="mt-5 col-12 sm:col-6 lg:col-4">
          <div className="card flex justify-content-center">
            <form className="p-fluid" onSubmit={onSubmitHandler}>
              <h3>Connexion</h3>
              <div className="field">
                <InputText
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="field">
                <InputText
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="grid">
                <Button
                  label="Se connecter"
                  className="p-button-sm p-component p-button-raised p-button-success col m-1"
                />
                <Link to="/forgetPassword">
                  <Button
                    label="Mot de passe oublié"
                    className="p-button-sm p-component p-button-raised p-button-danger flex-wrap col m-1"
                  />
                </Link>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
