/** @format */

import React, { Fragment, useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router";
import { ResetPasswordReq } from "../../services/AuthService";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [formInput, setFormInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const validateFormInput = (event) => {
    event.preventDefault();

    let inputError = {
      password: "",
      confirmPassword: "",
    };

    if (!formInput.password) {
      setFormError({
        ...inputError,

        password: "Password should not be empty",
      });
      return;
    }

    if (formInput.confirmPassword !== formInput.password) {
      setFormError({
        ...inputError,
        confirmPassword: "Password and confirm password should be same",
      });
      return;
    }

    if (!formInput.password) {
      setFormError({
        ...inputError,
        password: "Password should not be empty",
      });
      return;
    }
    ResetPasswordReq(formInput, token).then(() => {
      navigate("/login");
    });
    setFormError(inputError);
  };

  return (
    <Fragment>
      <div className="flex justify-content-center">
        <Card className="mt-5 col-12 sm:col-6 lg:col-4">
          <div className="card flex justify-content-center">
            <form className="p-fluid" onSubmit={validateFormInput}>
              <h3>Rénitialiser mot de passe</h3>

              <div className="field">
                <InputText
                  value={formInput.password}
                  onChange={({ target }) => {
                    handleUserInput(target.name, target.value);
                  }}
                  name="password"
                  type="password"
                  className="input"
                  placeholder="Mot de passe"
                ></InputText>
                <p className="error-message">{formError.password}</p>
                <Divider />
              </div>

              <div className="field">
                <InputText
                  value={formInput.confirmPassword}
                  onChange={({ target }) => {
                    handleUserInput(target.name, target.value);
                  }}
                  name="confirmPassword"
                  type="password"
                  className="input"
                  placeholder="Confirm Password"
                ></InputText>
                <p className="error-message">{formError.confirmPassword}</p>
                <Divider />
              </div>

              <Button
                label="Confirmer"
                className="p-button-sm p-component p-button-raised p-button-success"
              />
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
