/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { Card } from "primereact/card";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import AddAccount from "./AddAccount.jsx";
import AllAccounts from "./AllAccounts.jsx";
import { SearchAccount } from "../../services/AccountService.js";

export default function Index() {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const [filters, setFilters] = useState({
    page: currentPage,
    size: itemsPerPage,
    accountId: undefined,
    name: undefined,
    status: undefined,
  });

  const items = [{ label: "Liste des comptes" }];

  const home = {
    icon: "pi pi-home",
    url: "/",
  };
  const [createDialogAccount, setCreateDialogAccount] = useState(false);

  //Call to action
  const toggleCreateAccount = () => {
    setCreateDialogAccount(!createDialogAccount);
  };

  const refreshData = async () => {
    await SearchAccount({ ...filters }).then((res) => {
      setData(res.data);
      setLoaded(true);
    });
  };

  useEffect(() => {
    refreshData();
  }, [currentPage, itemsPerPage, filters]);

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Dialog
        visible={createDialogAccount}
        className="w-4 h-screen"
        modal
        onHide={toggleCreateAccount}
      >
        <AddAccount
          toggleCreateAccount={toggleCreateAccount}
          refreshData={refreshData}
          state={{ data, setData }}
        />
      </Dialog>

      <Card>
        {loaded ? (
          <AllAccounts
            data={data}
            toggleCreateAccount={toggleCreateAccount}
            filtersState={{ filters, setFilters }}
            pageState={{
              currentPage,
              itemsPerPage,
              setCurrentPage,
              setItemsPerPage,
            }}
          />
        ) : (
          <Fragment>
            <div className="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </Fragment>
        )}
      </Card>
    </Fragment>
  );
}
