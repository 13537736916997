import React, { Fragment, useState } from "react";
import { Paginator } from "primereact/paginator";
import NumberWithCommas from "../../components/Functions/NumberWithCommas.js";
export default function Pagination({ pageState, data, filtersState }) {
  //------------Template pagination
  const template = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  };

  const templateResult = {
    layout: "CurrentPageReport",
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            textAlign: "center",
          }}
        >
          &nbsp;Résultat {options.first} à {options.last} sur{" "}
          {NumberWithCommas(options.totalRecords)} résultats
        </span>
      );
    },
  };
  //------------Pagination-----------------
  const [init, setInit] = useState(0);
  const onBasicPageChange = (event) => {
    setInit(event.first);
    pageState.setItemsPerPage(event.rows);
    pageState.setCurrentPage(event.page + 1);
    filtersState.setFilters({
      page: event.page + 1,
      size: event.rows,
    });
  };
  return (
    <Fragment>
      <span>
        <Paginator
          totalRecords={data?.results}
          first={init}
          rows={pageState.itemsPerPage}
          pageLinkSize={3}
          template={template}
          onPageChange={onBasicPageChange}
          rowsPerPageOptions={[20, 50, 100, 500]}
          className="paginatorArrow"
        />
        <Paginator
          totalRecords={data?.results}
          first={init}
          rows={pageState.itemsPerPage}
          template={templateResult}
          className="paginatorResult"
        />
      </span>
    </Fragment>
  );
}
