/** @format */

import React, { Fragment } from "react";
import Index from "../../components/Accounts/Index.jsx";
import Title from "../../components/Functions/Title.jsx";

export default function Accounts() {
  return (
    <Fragment>
      <Title title={"Liste des comptes"} />
      <Index />
    </Fragment>
  );
}
