/** @format */

import React, { Fragment, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Register } from "../../services/AuthService";
import { Card } from "primereact";

export default function Inscription() {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "confirmer votre inscription ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Oui",
      denyButtonText: "Non",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        Register(data).then((res) => {
          setData(res);

          navigate("/login");
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "error").catch((err) => {
          console.error(err);
        });
      }
    });
  };

  return (
    <Fragment>
      <div className="flex justify-content-center">
        <Card className="mt-5 col-12 sm:col-6 lg:col-4">
          <div className="card flex justify-content-center">
            <form className="p-fluid" onSubmit={onSubmitHandler}>
              <h3>Inscription</h3>
              <div className="field">
                <InputText
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="field">
                <InputText
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                  onChange={onChangeHandler}
                />
              </div>

              <div className="field">
                <InputText
                  name="firstName"
                  type="text"
                  placeholder="Prénom"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="field">
                <InputText
                  name="lastName"
                  type="text"
                  placeholder="Nom"
                  onChange={onChangeHandler}
                />
              </div>

              <Button
                label="S'inscrire"
                className="p-button-sm p-component p-button-raised p-button-success"
              />
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
