/** @format */

import React, { Fragment, useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { CreateUserToAccount } from "../../services/UserToAccountService.js";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SearchAccessGroup } from "../../services/AccessGroupService.js";
import { toastCreateSuccess } from "../Functions/Toast.jsx";
import { Toast } from "primereact/toast";

export default function AddUserToAccount({
  accountId,
  toggleCreateUserToAccount,
  refreshAllUserToAccount,
}) {
  const toast = useRef(null);
  const [state, setState] = useState({});
  const [accessGroupOptions, setAccessGroupOptions] = useState([]);
  
  const onSubmitHandler = (e) => {
    e.preventDefault();
    CreateUserToAccount(accountId, state.accessGroupId, state.email).then(
      () => {
        toastCreateSuccess(toast, "Utilisateur ajouté !");
        refreshAllUserToAccount();
        setTimeout(() => {
          toggleCreateUserToAccount();
        }, 500);
      }
    );
  };
  const onChangeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    SearchAccessGroup().then((res) => {
      setAccessGroupOptions(res.data.accessGroupList);
    });
  }, []);

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h5>Ajouter un utilisateur à un account</h5>

          <div className="field">
            <InputText value={accountId} readOnly placeholder="accountId" />
            <InputText
              value={state.email}
              placeholder="email"
              name="email"
              onChange={onChangeHandler}
            />
            <Dropdown
              name="accessGroupId"
              value={state.accessGroupId}
              options={accessGroupOptions}
              onChange={onChangeHandler}
              optionLabel="name"
              optionValue="accessGroupId"
              placeholder="Select access group"
            />

            <Divider />
          </div>

          <Button
            label={"Ajouter"}
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </Fragment>
  );
}
