/** @format */

import React, { Fragment } from "react";
import Index from "../../components/Users/Index.jsx";
import Title from "../../components/Functions/Title.jsx";

export default function Users() {
  return (
    <Fragment>
      <Title title={"Liste des utilisateurs"} />
      <Index />
    </Fragment>
  );
}
