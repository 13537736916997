/** @format */

import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
import Pagination from "../Functions/Pagination.jsx";
import { Filters } from "../Functions/Filters.jsx";
import SliceString from "../Functions/SliceString.jsx";
import { dateFormat } from "../Functions/Date.jsx";

export default function AllMessages({
  data,
  pageState,
  toggleCreateMessage,
  filtersState,
}) {
  const bodyMessageId = (rowData) => {
    return <SliceString string={rowData.messageId} />;
  };
  const bodyAccountId = (rowData) => {
    return <SliceString string={rowData.accountId} />;
  };
  //---------Button Info-----------
  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link to={`/messages/${rowData.messageId}`}>
          <Button
            label={"Details"}
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-baseline">
        <h3>Liste des Messages</h3>
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
        <Button
          label={"Ajouter"}
          className="p-button-sm p-component p-button-raised p-button-success"
          onClick={toggleCreateMessage}
        ></Button>
      </div>
      <DataTable
        value={data?.messageList}
        responsiveLayout="scroll"
        scrollable
        globalFilterFields={[
          "messageId",
          "accountId",
          "status",
          "type",
          "recipient",
          "sentAt",
        ]}
        filterDisplay="row"
        currentPageReportTemplate={`${data?.currentPage} of ${data?.totalPages}`}
      >
        <Column
          sortable
          filter
          body={bodyMessageId}
          field="messageId"
          header={"id"}
          filterPlaceholder={"messageId"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"messageId"}
              value={filtersState.messageId}
              placeHolder="ID"
            />
          }
        />
        <Column
          sortable
          filter
          body={bodyAccountId}
          field="accountId"
          header={"accountId"}
          filterPlaceholder={"accountId"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"accountId"}
              value={filtersState.accountId}
              placeHolder="ID compte"
            />
          }
        />

        <Column
          sortable
          filter
          field="status"
          header={"status"}
          filterPlaceholder={"status"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"status"}
              value={filtersState.status}
              placeHolder="Status"
            />
          }
        />
        <Column
          sortable
          filter
          field="type"
          header={"type"}
          filterPlaceholder={"type"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"type"}
              value={filtersState.type}
              placeHolder="Type"
            />
          }
        />
        <Column
          sortable
          filter
          field="recipient"
          header={"recipient"}
          filterPlaceholder={"recipient"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"recipient"}
              value={filtersState.recipient}
              placeHolder="Client"
            />
          }
        />
        <Column
          sortable
          filter
          body={dateFormat(data.sentAt)}
          field="sentAt"
          header={"sentAt"}
          filterPlaceholder={"sentAt"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"sentAt"}
              value={filtersState.sentAt}
              placeHolder="Envoyé à"
            />
          }
        />

        <Column body={bodyDetails} />
      </DataTable>
      <ScrollTop threshold={300} behavior={"auto"} />
      <div className="flex flex-column justify-content-center">
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
      </div>
    </Fragment>
  );
}
