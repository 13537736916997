/** @format */

import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { BreadCrumb, Button, SplitButton } from "primereact";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Delete, Update } from "../../services/UserService";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import { Toast } from "primereact";
import { toastUpdateSuccess, toastCopyClipBoard } from "../Functions/Toast.jsx";
import SliceString from "../Functions/SliceString.jsx";

export default function DetailsUser({ userId, refreshData, state }) {
  const navigate = useNavigate();
  const toastCopy = useRef(null);
  const toastUpdate = useRef(null);

  const handleDelete = () => {
    Delete(userId).then((res) => {
      state.setData(res);
      navigate("/users");
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await Update(state.data, userId)
      state.setData(res.data);
      toastUpdateSuccess(toastUpdate, "Données mise à jour");
      refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const items = [
    {
      label: "Utilisateurs",
      url: "/users",
    },
    {
      label: <SliceString string={userId} />,
      url: `/users/${userId}`,
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: "Supprimer",
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopy = () => {
    navigator.clipboard.writeText(state.data?.userId);
    toastCopyClipBoard(toastCopy, "Contenu copié !");
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />

      <Card>
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>Utilisateur</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label={"Action"}
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>

        <form
          className="card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{"Utilisateur"}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.userId}
                      readOnly
                      name="userId"
                      type="text"
                      placeholder={"User ID"}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopy}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Prénom"}</h5>
                  <InputText
                    defaultValue={state.data?.firstName}
                    name="firstName"
                    type="text"
                    placeholder={"Prénom"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Nom"}</h5>
                  <InputText
                    defaultValue={state.data?.lastName}
                    name="lastName"
                    type="text"
                    placeholder={"Nom"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Email"}</h5>
                  <InputText
                    defaultValue={state.data?.email}
                    name="email"
                    type="email"
                    placeholder={"email"}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={"Mise à jour"}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastUpdate} />
          <Toast ref={toastCopy} />
        </form>
      </Card>
    </Fragment>
  );
}
