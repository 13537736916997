/** @format */

import React, { Fragment, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { DeleteUserToAccount } from "../../services/AccountService";
import { Button, Dialog } from "primereact";
import { useNavigate } from "react-router";
import UpdateUserToAccountAccessGroup from "./UpdateUserToAccountAccessGroup.jsx";

export default function ViewAllUserToAccount({
  state,
  refreshAllUserToAccount,
}) {
  const [updateDialog, setUpdateDialog] = useState({});
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const navigate = useNavigate();

  const handleDeleteUserToAccount = (accountId, userId) => {
    DeleteUserToAccount(accountId, userId).then((res) => {
      state.setAllUserToAccount(res);
      navigate("/accounts");
    });
  };

  //Call to action
  const toggleUpdateUserToAccount = (accountId, userId) => {
    setUpdateDialog({ accountId, userId });
    setUpdateDialogVisible(!updateDialogVisible);
  };

  const bodyAction = (rowData) => (
    <>
      <Button
        label={"X"}
        className="p-button-sm p-component p-button-raised p-button-danger"
        onClick={() =>
          handleDeleteUserToAccount(rowData.accountId, rowData.userId)
        }
      />
      <Button
        label={"modif"}
        className="p-button-outlined p-button-secondary"
        onClick={() =>
          toggleUpdateUserToAccount(rowData.accountId, rowData.userId)
        }
      />
    </>
  );

  return (
    <Fragment>
      <Dialog
        visible={updateDialogVisible}
        style={{ width: "50vw", height: "100vh" }}
        modal
        onHide={toggleUpdateUserToAccount}
      >
        <UpdateUserToAccountAccessGroup
          toggleUpdateUserToAccount={toggleUpdateUserToAccount}
          accountId={updateDialog.accountId}
          userId={updateDialog.userId}
          refreshAllUserToAccount={refreshAllUserToAccount}
        />
      </Dialog>

      <DataTable value={state.allUserToAccount}>
        <Column
          field="AccessGroup.name"
          header={"accessGroupId"}
          value={state.allUserToAccount.accessGroupId}
        />
        <Column field="User.email" header={"email"} />

        <Column body={bodyAction} />
      </DataTable>
    </Fragment>
  );
}
