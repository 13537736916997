/** @format */

import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { BreadCrumb, Button, SplitButton } from "primereact";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Delete, Update } from "../../services/MessageService.js";
import { toastUpdateSuccess, toastCopyClipBoard } from "../Functions/Toast.jsx";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import SliceString from "../Functions/SliceString.jsx";
import { Toast } from "primereact";

export default function DetailsMessage({ messageId, refreshData, state }) {
  const navigate = useNavigate();
  const toastCopy = useRef(null);
  const toastUpdate = useRef(null);

  const handleDelete = () => {
    Delete(messageId).then((res) => {
      state.setData(res);
      navigate("/messages");
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await Update(state.data, messageId).then((res) => {
        state.setData(res.data);
        toastUpdateSuccess(toastUpdate, "Données mise à jour");
        refreshData();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const items = [
    {
      label: "Messages",
      url: "/messages",
    },
    {
      label: <SliceString string={messageId} />,
      url: `/messages/${messageId}`,
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: "Supprimer",
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopyMessageId = () => {
    navigator.clipboard.writeText(state.data?.messageId);
    toastCopyClipBoard(toastCopy, "Contenu copié !");
  };

  const onClickCopyAccountId = () => {
    navigator.clipboard.writeText(state.data?.accountId);
    toastCopyClipBoard(toastCopy, "Contenu copié !");
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />

      <Card className=" col-12 ">
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>Message</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label={"Action"}
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>
        <form
          className="card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{"Utilisateur"}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.messageId}
                      name="messageId"
                      type="text"
                      readOnly
                      placeholder={"Message ID"}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyMessageId}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Compte ID"}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.accountId}
                      name="accountId"
                      type="text"
                      readOnly
                      placeholder={"Compte ID"}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyAccountId}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Status"}</h5>
                  <InputText
                    defaultValue={state.data?.status}
                    name="status"
                    type="text"
                    readOnly
                    placeholder={"Status"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Type"}</h5>
                  <InputText
                    defaultValue={state.data?.type}
                    name="type"
                    type="text"
                    readOnly
                    placeholder={"Type"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Client"}</h5>
                  <InputText
                    defaultValue={state.data?.recipient}
                    name="recipient"
                    type="text"
                    placeholder={"Client"}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{"Envoyé à"}</h5>
                  <InputText
                    defaultValue={state.data?.sentAt}
                    name="sentAt"
                    type="text"
                    placeholder={"Envoyé à"}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Toast ref={toastUpdate} />
          <Toast ref={toastCopy} />
        </form>
      </Card>
    </Fragment>
  );
}
