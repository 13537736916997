export const toastUpdateSuccess = (toast, message) => {
  toast.current.show({
    severity: "success",
    detail: message,
    life: 3000,
  });
};

export const toastCreateSuccess = (toast, message) => {
  toast.current.show({
    severity: "success",
    detail: message,
    life: 3000,
  });
};

export const toastError = (toast, message) => {
  toast.current.show({
    severity: "error",
    detail: message,
    life: 3000,
  });
};

export const toastCopyClipBoard = (toastCopy, message) => {
  toastCopy.current.show({
    severity: "success",
    detail: message,
    life: 3000,
  });
};
