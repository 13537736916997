/** @format */

import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";
import Pagination from "../Functions/Pagination.jsx";
import { Filters } from "../Functions/Filters.jsx";
import SliceString from "../Functions/SliceString.jsx";

export default function AllUsers({
  data,
  pageState,
  toggleCreateUser,
  filtersState,
}) {
  const bodyUserId = (rowData) => {
    return <SliceString string={rowData.userId} />;
  };
  //---------Button Info-----------
  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link to={`/users/${rowData.userId}`}>
          <Button
            label={"Details"}
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-baseline">
        <h3>Liste des utilisateurs</h3>
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
        <Button
          label={"Ajouter"}
          className="p-button-sm p-component p-button-raised p-button-success"
          onClick={toggleCreateUser}
        ></Button>
      </div>
      <DataTable
        value={data?.userList}
        responsiveLayout="scroll"
        scrollable
        globalFilterFields={[
          "userId",
          "firstName",
          "lastName",
          "email",
          "created",
        ]}
        filterDisplay="row"
        currentPageReportTemplate={`${data?.currentPage} of ${data?.totalPages}`}
      >
        <Column
          filter
          sortable
          field="userId"
          header={"ID"}
          body={bodyUserId}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"userId"}
              value={filtersState.userId}
              placeHolder="ID"
            />
          }
        />
        <Column
          filter
          sortable
          field="email"
          header={"Email"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"email"}
              value={filtersState.email}
              placeHolder="Email"
            />
          }
        />
        <Column
          filter
          sortable
          field="lastName"
          header={"Nom"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"lastName"}
              value={filtersState.lastName}
              placeHolder="Nom"
            />
          }
        />
        <Column
          filter
          sortable
          field="firstName"
          header={"Prénom"}
          filterElement={
            <Filters
              filtersState={filtersState}
              inputName={"firstName"}
              value={filtersState.firstName}
              placeHolder="Prénom"
            />
          }
        />

        <Column header="Détails" body={bodyDetails} />
      </DataTable>
      <ScrollTop threshold={300} behavior={"auto"} />
      <div className="flex flex-column justify-content-center">
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
      </div>
    </Fragment>
  );
}
