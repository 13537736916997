/* eslint-disable indent */
import React from "react";
import { Button } from "primereact/button";

export { Status };

function Status({ result }) {
  let severity;
  switch (result) {
    case "new":
      severity = "success";
      break;
    case "ok":
      severity = "info";
      break;
    case "ko":
      severity = "danger";
      break;
  }
  return (
    <Button
      label={result.toUpperCase()}
      className={
        "statusBtn p-button-sm p-component p-button-raised p-button-" + severity
      }
    />
  );
}
