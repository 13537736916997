/** @format */
import http from "./axios.js";

const CreateUserToAccount = (accountId, accessGroupId, email) => {
  return http.post(
    `/user-to-account/${accountId}/access-groups/${accessGroupId}/users`,
    { email },
    {
      withCredentials: true,
    }
  );
};

const AllUserToAccount = (filters) => {
  return http.post(
    "/user-to-account/search",
    {},
    {
      withCredentials: true,
      params: filters,
    }
  );
};

const GetOneUserToAccount = (userToAccountAccessGroupId) => {
  return http.get(`/user-to-account${userToAccountAccessGroupId}`, {
    withCredentials: false,
  });
};

export { AllUserToAccount, CreateUserToAccount, GetOneUserToAccount };
