/** @format */

/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";
import DetailsMessage from "../../components/Messages/DetailsMessage.jsx";
import { GetMessage } from "../../services/MessageService.js";

export default function OneMessage() {
  const { messageId } = useParams();
  const [data, setData] = useState({});
  const refreshData = async () => {
    await GetMessage(messageId)
      .then((res) => {
        setData(res.data.message);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);
  return (
    <Fragment>
      <DetailsMessage
        messageId={messageId}
        state={{ data, setData }}
        refreshData={refreshData}
      />
    </Fragment>
  );
}
