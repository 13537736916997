/** @format */

import React, { Fragment, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Register } from "../../services/AuthService.js";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import { toastCreateSuccess } from "../Functions/Toast.jsx";
import { Toast } from "primereact/toast";

export default function AddUser({ refreshData, state, toggleCreateUser }) {
  const toast = useRef(null);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await Register(state.data)
        .then((res) => {
          state.setData(res.data);
          toastCreateSuccess(toast, "Utilisateur créé !");
          setTimeout(() => {
            refreshData();
            toggleCreateUser();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>Création utilisateur</h3>
          <div className="field">
            <InputText
              name="email"
              type="email"
              placeholder="Email"
              onChange={onChange(state)}
            />
          </div>
          <div className="field">
            <InputText
              name="password"
              type="password"
              placeholder="Mot de passe"
              onChange={onChange(state)}
            />
          </div>

          <div className="field">
            <InputText
              name="lastName"
              type="text"
              placeholder="Nom"
              onChange={onChange(state)}
            />
          </div>
          <div className="field">
            <InputText
              name="firstName"
              type="text"
              placeholder="Prénom"
              onChange={onChange(state)}
            />
          </div>

          <Button
            label="Ajouter"
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </Fragment>
  );
}
