/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";
import DetailsUser from "../../components/Users/DetailsUser.jsx";
import Title from "../../components/Functions/Title.jsx";
import { GetUser } from "../../services/UserService";
import ViewAllUserToAccountEmail from "../../components/UserToAccount/AllUserToAccountEmail.jsx";

export default function OneUser() {
  const { userId } = useParams();

  const [data, setData] = useState({});

  const refreshData = async () => {
    await GetUser(userId)
      .then((res) => {
        setData(res.data.user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);
  return (
    <Fragment>
      <Title title={data?.firstName + " " + data?.lastName} />
      <DetailsUser
        userId={userId}
        state={{ data, setData }}
        refreshData={refreshData}
      />
      <ViewAllUserToAccountEmail userId={userId} />
    </Fragment>
  );
}
